.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  /* color: #cccccc; */
  font-family: 'Prompt', Helvetica;
  font-size: 20px;
  /* font-weight: 200; */
  font-weight: 700;
  color: #000000;
}

.recording {
  color: firebrick;
  padding-left: 1.2em;
}

.recording::before {
  content: '🔴';
  margin-right: 3px;
  animation: recording 600ms alternate infinite;
}
@keyframes recording {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.text-white {
  color: white;
}

/* Login Page */
.login-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.login-tabbar {
  display: flex;
  align-items: center;
}
.login-tab {
  padding: 10px 20px;
  border-bottom: solid 1px #DDDDDD;
  cursor: pointer;
}
.login-tab-selected {
  border: solid 1px #DDDDDD;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 50vh;
  border: solid 1px #DDDDDD;
  border-radius: 10px;
}
.login-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 60vh;
  border: solid 1px #DDDDDD;
  border-radius: 10px;
}
.login-content-no-border {
  border: none !important;
}
.login-form {
  display: flex;
  flex-direction: column;
}
.wx-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 96%;
}

/* User Page */
.user-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.text-button {
  color: blue;
  cursor: pointer;
}

/* nextui */
.nextui-button-text {
  color: white !important;
}
input[type='text'] {
  color: black !important;
}

