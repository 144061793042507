.mobile-page {
  width: 100vw;
  height: 100vh;
}
.chat-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}
.chat-bg img {
  width: inherit;
  height: inherit;
  object-fit: fill;
}
.chat-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 50vh;
  padding: 16px 0;
  box-sizing: border-box;
}
.message-list {
  flex: 1 1;
  padding: 0 16px;
  overflow: auto;
}
.message-list .message-item {
  width: 100%;
  font-size: 12px;
  white-space: pre-wrap;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.message-list .message-item:last-child {
  margin-bottom: 0px !important;
}
.message-list .ai {
  background: white !important;
  border-radius: 10px 10px 10px 0 !important;
}
.message-list .user {
  background: linear-gradient(55deg, rgba(232, 204, 254, 0.90) 28.69%, rgba(172, 205, 255, 0.90) 90.93%) !important;
  border-radius: 10px 10px 0 10px !important;
}
.send-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 239px;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  background: linear-gradient(68deg, rgba(154, 195, 255, 0.90) 37.12%, rgba(255, 113, 224, 0.90) 69.43%);
  margin-top: 16px;
  border-radius: 48px;
}
.send-button-disabled {
  color: #999999;
  background: #DDDDDD !important;
}
.record-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
.record-container .record-icon {
  height: 56px;
}
.record-container .record-icon img {
  height: 100%;
}
.record-container .record-tip {
  font-size: 12px;
  color: #CCCCCC;
  margin-top: 134px;
  margin-bottom: 34px;
}
.record-container .record-button {
  position: relative;
  width: 100%;
}
.record-container .record-button > img {
  width: 100%;
}
.record-container .shengbo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
}
.record-container .shengbo > img {
  width: 100%;
}
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
}
.bottom-safe-area {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}