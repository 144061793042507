.survey {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(339deg, #EFF4FF 0%, #EBE4FF 100%), #FFFFFF;
    position: relative;
}

.bg {
    width: 100%;
}

.detail {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* padding: 0 18px; */
    padding-top: 11px;
    padding-bottom: 50px;
}

.rw {
    width: 50%;
    height: 167px;
    margin-left: 8px;
}

.hint {
    width: 178px;
    height: 67px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 8px 0px rgba(128, 3, 253, 0.06);
    border-radius: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 49px;
    left: 48%;
}

.hint_text {
    width: 150px;
    height: 40px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    line-height: 22px;
    position: relative;

}

.icon1 {
    position: absolute;
    top: -6px;
    right: 32px;
    width: 20px;
    height: 18px;
}

.info {
    position: relative;
    margin: 0 auto;
    margin-top: -10px;
    width: 93%;
    min-height: 578px;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    /* overflow: scroll; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    padding: 22px 0;
}

.top_jindu {
    display: flex;
    align-items: center;
}

.now {
    margin-left: 15px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    line-height: 23px;
}

.jindu_bg {
    width: 266px;
    height: 10px;
    background: #EFF4FF;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid #DFDDFF;
    position: relative;
}

.jindu {
    position: absolute;
    top: -2px;
    left: -1px;
    width: 0;
    height: 10px;
    background: #32EA97;
    box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.64), 1px 0px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #18CA79;
}


.next {
    width: 83vw;
    height: 53px;
    background: #D9D9D9;
    border-radius: 30px 30px 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}

.next-a {
    background: #8003FD;
    box-shadow: inset 0px -4px 0px 0px rgba(45, 0, 168, 0.6), 0px 4px 4px 0px #CFCDFC;
}

.titlea {
    width: 80%;
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 18px;
    color: #000000;
    margin-top: 26px;
    -webkit-line-clamp: 2;
    /* 限制为两行 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.selections {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}

.selection {
    width: 83vw;
    height: 53px;
    background: #FFFFFF;
    box-shadow: 0px 2px 0px 0px #CFCDFC, 0px 4px 2px 0px rgba(207, 205, 252, 0.3);
    border-radius: 30px 30px 30px 30px;
    border: 2px solid #CFCDFC;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 15px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}

.selection-a {
    background: #EAE9FF;
    box-shadow: 0px 2px 0px 0px #CFCDFC, 0px 4px 2px 0px rgba(207, 205, 252, 0.3);
    border-radius: 30px 30px 30px 30px;
    border: 2px solid #CFCDFC;
}

.desc {
    margin-top: 10px;
    width: 241px;
    height: 34px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.7);
    line-height: 22px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}

.input_no {
    width: 288px !important;
    height: 53px;
    background: #FFFFFF;
    border-radius: 30px 30px 30px 30px;
    border: 2px solid #CFCDFC;
    margin-top: 23px;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 15px;
    color: #000000;
}

.input-a {
    border: 2px solid #7F5FFF;
}

.input_no::placeholder {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.3);
}