h1,
h2,
h4,
li,
p {
  /* color: white; */
  color: #000000;
}

.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-bottom: 30px;
}

.start-btn {
  padding: 30px;
}

.conversation-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shared-conversation-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  text-align: center;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.pulsating-avatar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s infinite;
  opacity: 0;
  z-index: -1;
}

.text-area {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: large;
}

@media (min-width: 768px) {
  .md-align-self {
    align-self: stretch;
  }
}