label {
  color: #e0e0e0;
}

.select {
  /* background-color: #1b2134; */
  /* color: #ffffff; */
  color: #000000;
  border-color: #1b2134;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}
