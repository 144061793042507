@keyframes audio-wave {
  0% {
    height: 10px;
    trnsform: translateY(0px);
    background: #1f4fcc;
  }
  25% {
    height: 40px;
    trnsform: translateY(-5px);
    scaley: 1.7;
    background: #6785d3;
  }
  50% {
    height: 10px;
    trnsform: translateY(0px);
    scaley: 1.7;
    background: #c2d3ff;
  }
  100% {
    height: 10px;
    trnsform: translateY(0px);
    scaley: 1.7;
    background: fixed #eef3ff;
  }
}

.sound-wave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 60px;
}
.sound-wave span {
  height: 18px;
  width: 10px;
  display: block;
  border-radius: 8px;
  background: #bec5d9;
  animation: audio-wave 2.2s infinite ease-in-out;
}
.stop-animation span {
  animation-play-state: paused;
}
.sound-wave span:nth-child(2) {
  left: 11px;
  background: #ffffff;
  animation-delay: 0.2s;
}
.sound-wave span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}
.sound-wave span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}
.sound-wave span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}
.sound-wave span:nth-child(6) {
  left: 55px;
  animation-delay: 1s;
}

.audio-player {
  display: flex;
}

.hidden {
  display: none;
}

.options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  bottom: 0;
  width: 100%;
}

.call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between;
  flex-grow: 1; */
}

.call-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between;
  flex-grow: 1; */
  /* width: 100%;
  height: 30vh; */
  width: 40vw;
  padding: 20px;
}
