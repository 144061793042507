.mobile-page {
  width: 100vw;
  height: 100vh;
}
.chat-bg1 {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}
/* .chat-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.bg {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.bgobc{
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.chat-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  /* height: 50vh; */
  height: 58vh;
  padding: 16px 0;
  box-sizing: border-box;
}
.message-list {
  flex: 1 1;
  padding: 0 16px;
  overflow: auto;
}
.message-list .message-item {
  /* width: 100%; */
  width: calc(100vw - 32px);
  font-size: 12px;
  white-space: pre-wrap;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  /* user-select: none; */
}
.message-list .message-item:last-child {
  margin-bottom: 0px !important;
}
.message-list .ai {
  /* position: relative;
  padding: 18px;
  background: rgba(61, 70, 174, 0.84) !important;
  border-radius: 0px 20px 20px 20px !important;
  margin-bottom: 16px;
  display: inline-block;
  width: 287px;
  font-size: 16px;
  font-family: Helvetica, Helvetica;
  font-weight: 400;
  color: white;
  line-height: 20px; */

  position: relative;
  padding: 18px;
  background: rgba(61, 70, 174, 0.84) !important;
  border-radius: 0px 20px 20px 20px !important;
  margin-bottom: 16px;
  /* flex: 1; */
  display: inline-block;
  width: 370px;
  font-size: 24px;
  font-family: Helvetica, Helvetica;
  font-weight: 400;
  color: white;
  line-height: 34px;
  /* user-select: none; */
  /* user-select: none; */
}
.message-list .user {
  /* background: linear-gradient(
    55deg,
    rgba(232, 204, 254, 0.9) 28.69%,
    rgba(172, 205, 255, 0.9) 90.93%
  ) !important;
  border-radius: 10px 10px 0 10px !important;
  font-size: 16px;
  line-height: 27px;
  padding: 17px 17px;
  padding-right: 43px; */
  /* width: 287px; */
  width: 370px;
  padding: 18px;
  border-radius: 20px 0px 20px 20px !important;
  background: linear-gradient(
    141.14deg,
    rgba(163, 69, 237, 0.87) 0%,
    rgba(125, 82, 216, 0.87) 100%
  ) !important;
  /* font-size: 13px; */
  font-size: 24px;
  font-family: Helvetica, Helvetica;
  font-weight: 400;
  color: white;
  line-height: 34px;
  /* float: left; */
  margin-left: 130px;
  /* margin-right: 20px; */
  /* user-select: none; */
}
.send-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 239px;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  background: linear-gradient(
    68deg,
    rgba(154, 195, 255, 0.9) 37.12%,
    rgba(255, 113, 224, 0.9) 69.43%
  );
  margin-top: 16px;
  border-radius: 48px;
  margin-bottom: 30px;
  user-select: none;
}
.send-button-disabled {
  color: #999999;
  background: #dddddd !important;
  user-select: none;
}
.record-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
.record-container .record-icon {
  height: 56px;
}
.record-container .record-icon img {
  height: 100%;
}
.record-container .record-tip {
  font-size: 12px;
  color: #cccccc;
  margin-top: 134px;
  margin-bottom: 34px;
}
.record-container .record-button {
  position: relative;
  width: 100%;
}
.record-container .record-button > img {
  width: 100%;
}
.record-container .shengbo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
}
.record-container .shengbo > img {
  width: 100%;
}
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
}
.bottom-safe-area {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.prologue {
  width: 80%;
  background: rgba(6, 11, 25, 0.78);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 14px;
  margin-bottom: 16px;
}
.prologue-name {
  color: white;
}
.prologue-slogan {
  color: #c0ffff;
}
.content {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.left-cont {
  width: 38%;
  position: relative;
  display: flex;
  justify-content: center;
}
.left-people {
  width: 53%;
  position: absolute;
  bottom: 171px;
}
.left-wu {
  width: 100%;
  position: absolute;
  bottom: 73px;
  height: 474px;
}
.left-foot {
  width: 80%;
  position: absolute;
  bottom: 0;
  height: 213px;
}
.right-cont {
  flex: 1;
  position: relative;
}
.talk-box {
  width: 286px;
  height: 80px;
  /* margin-top: 82px; */
  position: absolute;
  bottom: 42px;
  margin-left: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.talk-ball {
  width: 92px;
  cursor: pointer;
}
.sum-right {
  width: 252px;
  margin-left: 32px;
}
.message-box {
  /* width: 520px; */
  width: 600px;
  /* width: 440px; */
  /* height: 429px; */
  /* height: 520px; */
  /* height: 60%; */
  height: 70%;
  /* margin-top: 40px; */
  overflow: auto;
  position: absolute;
  top: 70px;
}
.none-btn {
  display: none;
}
.ai-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 14px;
  float: left;
}
.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: 14px;
  /* float: right; */
}
.cancal-record {
  width: 40px;
  height: 40px;
  margin-right: 17px;
  cursor: pointer;
}
.send-record {
  width: 61px;
  margin-right: 17px;
  cursor: pointer;
}
.sayz-record {
  width: 143px;
}
.talk-bottom {
  position: absolute;
  width: 51px;
  bottom: 30px;
  right: 35px;
  cursor: pointer;
}
.mask-talk {
  width: 405px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  background: rgba(15, 18, 56, 0.89);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mask-talk-box {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.talk-list {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
}
.close-talk {
  width: 51px;
  position: absolute;
  right: 35px;
  bottom: 30px;
  /* height: 51px; */
  cursor: pointer;
}
.talk-list p {
  position: absolute;
  color: rgba(193, 204, 255, 1);
  text-align: center;
  font-size: 22px;
}
.talk-img {
  width: 320px;
}
.sum-right {
  width: 252px;
  margin-left: 32px;
}
.talk-bottom {
  position: absolute;
  width: 51px;
  bottom: 30px;
  right: 35px;
  cursor: pointer;
}
.message-boxer {
  margin-top: 20px;
  display: flex;
  width: 100%;
}
