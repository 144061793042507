.main-container {
  flex-direction: row;
}

.character-item {
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: transparent !important;
    .character-avatar {
      border: 1px solid #617CC2;
    }
  }
}

.character-avatar {
  box-sizing: border-box;
  margin-bottom: 1vw;
}
